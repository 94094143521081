
<template>
   <body>
      <div class="divStart" id="inicio">
         <img class='header_background' src="../assets/foto1.jpg" alt="Foto do logo Paçoca Terraplanagem">
         <aside class="title-header">
            <img class="border" src="../assets/logo3.png" alt="Foto do logo Paçoca Terraplanagem">
         </aside>
      </div>

   </body>
</template>

<style scoped>
   .header_background {
   width: 100%;
   max-width: 100%;
   background-size: cover;
   box-shadow: 0 0 5px;
   }

   .title-header {
      font-family: 'Armata', sans-serif;
      font-size: 3em;
      color: #FFF;
      text-shadow: 2px 2px 2px #000;
      width: 30%;
      z-index: 1;
      position: absolute; 
      top: 38%; 
      right: 10%;
   }

   .border{
      border: double 8px transparent;
      border-radius: 70px;
      background-image: linear-gradient(rgb(0, 0, 0), rgb(130, 130, 130)), radial-gradient(circle at top left, rgb(0, 0, 0), rgb(51, 51, 51));
      background-origin: border-box;
      background-clip: content-box, border-box;
   }

   img{
      background-origin: border-box;
      background-clip: content-box, border-box;
   }

   .divStart{
      position: relative;
   }



   @media only screen and (max-width: 700px) {
   .header_background {
   width: 100%;
   min-height: 30em;
   object-fit: cover;
   object-position: center left;
   box-shadow: 0 0 5px;
   }

   .title-header {
      text-shadow: 2px 2px 2px #000;
      width: 30%;
      position: absolute; 
      top: 75%;
      right: 25%;
      left: 25%;
      z-index: 1;
   }

   .title-header img{
      width: 170%;
   }

   .border{
      border: double 4px transparent;
      border-radius: 70px;
      background-image: linear-gradient(rgb(0, 0, 0), rgb(130, 130, 130)), radial-gradient(circle at top left, rgb(0, 0, 0), rgb(51, 51, 51));
      background-origin: border-box;
      background-clip: content-box, border-box;
   }

   img{
      background-origin: border-box;
      background-clip: content-box, border-box;
   }
}
</style>