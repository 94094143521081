<template>
  <a name="sobre"></a>
  <div class="fundo" id="sobre">
    <div class="orcamento">
      <div class="textoSobre">
        <h1>Sobre nós</h1><br>
        <p>Nós somos uma empresa jovem, mas com uma equipe altamente experiente no setor de terraplanagem, 
          totalizando mais de 25 anos de expertise. Oferecemos uma ampla gama de serviços de terraplanagem, 
          incluindo remoção de solo superficial, movimentação de materiais, compactação, corte, aterro, 
          limpeza de represas, escavação para silos, cascalhamento, remoção de tocos de árvores e muito mais, 
          garantindo soluções para as mais diversas necessidades.</p><br>
        <p>Estamos sediados na cidade de Ponta Grossa e atendemos toda a região dos Campos Gerais, 
          fornecendo serviços de alta qualidade com rapidez para melhor atender nossos clientes. Seguindo as 
          regulamentações ambientais e de segurança para garantir que o trabalho seja feito de forma segura e 
          sustentável. </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.orcamento {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 20px;
  background-color: #f6f6f6;
  margin: 0px 10em;
  color: rgb(0, 0, 0);
  border-radius: 10px;
}

.fundo{
  margin: 0px;
  padding: 50px 0px;
  width: 100%;
}

p{
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

@media only screen and (max-width: 700px) {
  .orcamento {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #d8d8d8;
    margin: 0px 40px;
    color: rgb(0, 0, 0);
    border-radius: 10px;
  }
}
</style>
