<template>
  <a name="contato"></a>
  <div class="backfooter" id="contato">
    <footer class="footer">
      <div></div>
      <div class="align-items-center img-footer">
        <img src="../assets/logo3rbg.png" alt="Logo da Empresa">
      </div>
      <div class="align-items-center">
        <p>
          Endereço: <br><br>
          Rua Sabia, 600<br>
          Piriquitos <br>
          Ponta Grossa - PR<br><br>
        </p>
      </div>
      <div class="align-items-center">
        <p>
          Contato: <br><br>
          Edivaldo: (42) 9 9905 - 0960 <br>
          Luis: (42) 9 9106 - 7603 <br>
          E-mail: <a href="mailto:pacocaterraplanagem@gmail.com">pacocaterraplanagem@gmail.com</a><br><br>
        </p>
      </div>
      <!--<div class="align-items-center">
        <p>
          Redes Sociais: <br><br>
          <a href="#">Facebook</a> <br>
          <a href="#">Instagram</a> <br>
          <a href="#">Twitter</a>
        </p>
      </div>-->
      <div></div>
    </footer>
  </div>
</template>

<style scoped>
.footer {
  background-image: url('../assets/ESCAVAÇÃOEMGERAL.png');
  background-size: cover;
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  margin-bottom: -20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.img-footer img{
  width: 100%;
}

.backfooter{
  background-color: #000000;
}

.footer a {
  color: #ffffff;
  text-decoration: none;
}

.footer p {
  margin-top: 2em;
  font-size: 17px;
  color: #ffffff;
  text-align: left;
  align-items: center;
  justify-content: center;
  font-family: 'Armata', sans-serif;
}

.align-items-center{
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 700px) {
  .img-footer img{
    width: 70%;
    margin: auto;
  }
  .footer {
    flex-direction: column;
    justify-content: left;
    padding-bottom: 7em;
  }
  .footer p {
    margin-bottom: 10px;
    margin-left: 50px;
    font-size: 15px;
    text-align: left;
    align-items: left;
    justify-content: left;
  }
  .align-items-center{
    display: flex;
    align-items: left;
  }
}
</style>