
<template>

<head>
   <title>Paçoca Terraplanagem</title>
   <meta charset="utf-8">
   <meta name="author" content="Schaffen IT">
   <link href="https://fonts.googleapis.com/css?family=Quicksand" rel="stylesheet">
   <link href="https://fonts.googleapis.com/css?family=Armata" rel="stylesheet">
   <link rel="stylesheet" type="text/css" href="style.css">
</head>

<a name="inicio"></a>
   <body>
       <header>
            <div class="conteiner">
               <div class="divImg">
                  <img src="../assets/logo5rbg.png" alt="Foto do logo Paçoca Terraplanagem">
               </div>
               <div class="divNav">
                  <nav>
                     <ul>
                        <li><a @click="scrollToAnchor('inicio')">INÍCIO</a></li>
                        <li><a @click="scrollToAnchor('servicos')">SERVIÇOS</a></li>
                        <li><a @click="scrollToAnchor('sobre')">SOBRE</a></li>
                        <li><a @click="scrollToAnchor('contato')">CONTATO</a></li>
                     </ul>
               </nav>
               </div>
            </div>
         </header>
         <div class="linkWhatsapp">
            <a href="https://wa.me/5542998427294?text=Olá,%20Gostaria%20de%20fazer%20um%20orçamento">
               <img src="../assets/Whatsapp.png" alt="">
            </a>
         </div>
   </body>
</template>

<script>
   export default {
      methods: {
         scrollToAnchor(name) {
            // get the height of the fixed header
            const header = document.querySelector('header');   
            const headerHeight = header.offsetHeight;

            console.log(headerHeight)

            // calculate the target position of the anchor
            const targetElement = document.getElementById(name);

            console.log(targetElement)

            if (!targetElement) {
               return; // target element doesn't exist, exit the function
            }
            const targetPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;

            console.log(targetElement)

            // scroll to the target position
            window.scrollTo({
               top: targetPosition,
               behavior: 'smooth'
            });
         }
      }
   };
</script>

<style scoped>

header {
   width: 101%;
   background-color: rgb(255, 255, 255);
   position: fixed;
   top: 0;
   z-index: 99;
   justify-content: center;
   align-items: center;
   box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.linkWhatsapp{
   position: fixed;
   bottom: 5%;
   right: 2%;
   z-index: 99;
}

.linkWhatsapp img{
   width: 80px;
}

.conteiner{
   max-width: 100em;
   display: flex;
   justify-content: center;
   align-items: center;
   margin: auto;
}

.divImg{
   width: 30%;
}

.divNav{
   width: 70%;
}

header img {
   width: 170px;
   margin: auto;
}

nav {

}

nav ul {
   margin: auto;
   text-align: center;
   justify-content: center;
}

nav ul li {
   font-family: 'Quicksand', sans-serif;
   display: inline-block;
   font-size: 1.0em;
   margin: auto;
   margin-right: 2.5em;
   
}

nav ul li:first-child {
   margin-left: 0;
}

nav ul li a {
   text-decoration: none;
   color: #000000;
   font-weight: bold;
   text-shadow: 2px 2px 3px #bbbbbb;
}

nav ul li a:hover {
   transition: 1s;
   color: #af7a1f;
   text-shadow: 0 0 4px #ffffff;
}



@media only screen and (max-width: 700px) {
   header {
      width: 101%;
      background-color: rgb(255, 255, 255);
      position: fixed;
      top: 0em;
      z-index: 99;
      justify-content: center;
      align-items: center;
      box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
   }

   .conteiner{
      max-width: 100em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      display: block;
   }

   .divImg{
      width: 100%;
   }

   .divNav{
      width: 100%;
   }

   header img {
      width: 170px;
      margin: auto;
   }

   nav {

   }

   nav ul {
      margin: auto;
      text-align: center;
      justify-content: center;
   }

   nav ul li {
      font-family: 'Quicksand', sans-serif;
      display: inline-block;
      font-size: 0.7em;
      margin: auto;
      margin-right: 2.5em;
      
   }

   nav ul li:first-child {
      margin-left: 0;
   }

   nav ul li a {
      text-decoration: none;
      color: #000000;
      font-weight: bold;
      text-shadow: 2px 2px 3px #bbbbbb;
   }

   nav ul li a:hover {
      transition: 1s;
      color: #af7a1f;
      text-shadow: 0 0 4px #ffffff;
   }
}
</style>