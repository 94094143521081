<template>
  <div class="orcamento">
    <img src="../assets/barra_lama.png" alt="">
  </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.orcamento {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: rgb(0, 0, 0);
}

img {
  width: 100%;
  margin-top: -1px;
  height: auto;
  transform: scaleY(-1) scaleX(-1);
}

@media only screen and (max-width: 700px) {
  img {
    width: 100%;
    height: auto;
    transform: scaleY(-1) scaleX(-1);
  }
}
</style>
