<template>
   <div class="servico" id="servicos">
      <div class="titulo">
         <h1>Serviços</h1>
      </div>
      <div class="allCards">
         <div class="card">
            <img src="../assets/servicos_1.png" alt="Escavação em geral">
            <div class="card-text">
               <h3>Serviço de escavação em geral</h3>
                  <ul>
                     <li>Remoção de solo superficial</li>
                     <li>Movimentação de material</li>
                     <li>Compactação</li>
                     <li>Corte</li>
                     <li>Aterro</li>
                     <li>Limpeza de represa</li>
                     <li>Buraco pra silo</li>
                     <li>Cascalhamento</li>
                     <li>Retirada de tocos de árvore</li>
                     <li>Terraplanagem em geral</li>
                  </ul><br>
               <p>
               Nossos serviços oferecem soluções para as mais diversas necessidades. Contamos com equipamentos de alta qualidade e uma equipe de profissionais altamente capacitados e experientes para executar as tarefas com precisão e segurança.
               </p>
            </div>
         </div>
         <div class="card">
            <img src="../assets/servicos_2.png" alt="Aluguel de máquinas e equipamentos para construção">
            <div class="card-text">
               <h3>Aluguel de máquinas e equipamentos para construção</h3>
               <ul>
                  <li>Escavadeira Hidráulica</li>
                  <li>Trator de Esteiras</li>
                  <li>Pá Carregadeira</li>
                  <li>Motoniveladora</li>
                  <li>Vibroacabadora</li>
                  <li>Rolo Compactador</li>
                  <li>Extrusora de perfil de concreto</li>
                  <li>Compactador de solo a percussão</li>
                  <li>Placa Vibratória</li>
                  <li>Tirefonadeira</li>
               </ul><br>
               <p>
               Oferecemos serviços de aluguel de máquinas e equipamentos para atender as mais diversas necessidades. Se o que você procura não esta na lista acima não se preocupe, entre em contato conosco e veremos a disponibilidade do equipamento perfeito para seu projeto.
               </p>
            </div>
         </div>
         <div class="card">
            <img src="../assets/servicos_3.png" alt="Aluguel de andaimes">
            <div class="card-text">
               <h3>Frete de Máquinas</h3>
               <p>
               Oferecemos um serviço de frete de equipamentos pesados com caminhão prancha. Uma solução logística que oferece transporte seguro e eficiente de cargas de grande porte, como máquinas industriais, tratores, escavadeiras e outros equipamentos. <br><br>
               Esse tipo de serviço é ideal para empresas que precisam movimentar equipamentos pesados em obras, empreendimentos industriais ou em outros locais que exigem transporte especializado. <br><br>
               O serviço de frete com Caminhão Prancha inclui desde o planejamento da rota até o transporte seguro e a entrega da carga no destino final, garantindo a qualidade e a segurança do serviço prestado.
               </p>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
export default {
  name: 'HeaderMenu',
  props: {
    msg: String
  },
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.servico {
   justify-content: space-evenly;
   align-items: center;
   padding: 20px;
   margin: 20px;
   color: rgb(0, 0, 0);
}

.allCards {
   display: flex;
   flex-wrap: wrap;
   justify-content: center;
}

.card {
   margin: 20px;
   background-color: #fff;
   padding: 20px;
   border-radius: 10px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
   transition: transform 0.2s ease-in-out;
   max-width: 27%;
}

.card:hover {
   transform: scale(1.05);
}

.card img {
   width: 100%;
   height: 20em;
   margin-bottom: 20px;
   object-fit: cover;
   border: double 1px;
   border-radius: 7px;
   border-color: rgb(145, 145, 145);
}

.card-text {
   text-align: center;
}

ul{
   width: 90%;
   text-align: left;
   padding: 0 2em;
}

p{
   text-align: justify;
}

h3{
   margin-bottom: 20px;
}
@media only screen and (max-width: 700px) {
   .servico {
      justify-content: space-evenly;
      align-items: center;
      padding: 5px;
      margin: 5px;
      color: rgb(0, 0, 0);
   }
   .card {
   margin: 5px;
   background-color: #fff;
   padding: 20px;
   border-radius: 10px;
   box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
   transition: transform 0.2s ease-in-out;
   max-width: 80%;
}
}
</style>
