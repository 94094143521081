<template>
  <HeaderMenu/>
  <div class="bodyType">
    <StartOfHomePage/>
    <Servicos/>
    <LamaSeparatorStart/>
    <Orçamento/>
    <LamaSeparatorEnd/>
    <WaveSeparatorStart/>
    <div class="flex">
        <Sobre/>
    </div>
    <HomeFooter/>
  </div>
</template>

<script>
import HeaderMenu from './components/HeaderMenu.vue'
import StartOfHomePage from './components/StartOfHomePage.vue'
import Orçamento from './components/Orçamento.vue'
import HomeFooter from './components/HomeFooter.vue'
import Servicos from './components/Servicos.vue'
import LamaSeparatorStart from './components/Lama-separator-start.vue'
import LamaSeparatorEnd from './components/Lama-separator-end.vue'
//import FormContato from './components/FormContato.vue'
import Sobre from './components/Sobre.vue'


export default {
  name: 'App',
  components: {
    HeaderMenu,
    StartOfHomePage,
    Orçamento,
    Servicos,
    LamaSeparatorStart,
    LamaSeparatorEnd,
    //FormContato,
    Sobre,
    HomeFooter
  },
  watch: {
    title: {
      immediate: true,
      handler() {
        document.title = 'Paçoca Terraplanagem';
      }
    }
  },
}
</script>

<script>
   
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
*{
    margin: 0;
    padding: 0;
}
html{
  background-color: #fce9c8;
  margin: -8px;
}
body {
  overflow-x: hidden;
}

.bodyType{
  max-width: 100em;
  margin: auto;
  background-color: #d9c29a;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
}

.hr1 {
  border: 0;
  height: 5px;
  width: 50%;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  background-image: linear-gradient(to right, transparent, #ffffff, transparent);  
}

.titulo{
  display: block;
  margin-bottom: 1em;
  justify-content: center;
  font-family: 'Armata', sans-serif;
  font-size: 2em;
  color: #FFF;
  text-shadow: 2px 2px 2px #000;
  z-index: 1;
}

.flex{
  display: flex;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .titulo{
    font-size: 1em;
  }
}
</style>
